@import 'backoffice-navigation';
@import 'search-bar';
@import 'back-button-bar';
@import 'stock-exchange-bar';
@import 'event-notifications';
@import 'messages';
@import 'sidebar-menu';
@import 'language-selection';

$fold-main-menu-breakpoint-x: 1080px;
$fold-main-menu-breakpoint-y: 600px;
@import 'main-menu';

#dedere-header {
  position: relative;
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin: 0 auto 1rem;
  padding: 0.5rem 0.5rem 0;
  border-bottom: 1px solid $medium-gray;
  z-index: 100;
  background-color: $white;
  max-height: 100%;
  width: 100%;
  top: 0;

  a {
    text-decoration: none;
  }

  li i {
    width: auto;
  }

  .dh-header-inner {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;

    @include xy-grid-container;
  }

  .column-logo {
    flex: 0 1 auto;
    order: 1;
    align-items: flex-end;
    display: flex;
    position: relative;

    a {
      display: inline-block;

      &:focus {
        opacity: 0.75;
      }
    }

    .dh-context {
      background-color: $highlight-color;
      padding: 2px 3px;
      color: $white;
      font-size: 11px;
      line-height: 1;
      margin-left: 5px;
      margin-right: 1rem;
      white-space: nowrap;
      transition: background-color 150ms ease-in-out;

      &:hover {
        background-color: darken($highlight-color, 5%);
      }
    }
  }

  .dh-logo {
    width: 110px;
    height: 27px;
    background-image: url('~application/src/images/logo-mundus-agri-w200.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    background-color: transparent;
    image-rendering: -o-crisp-edges;
    /* Opera */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming)*/
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
  }

  @media only screen and (max-width:1200px) {
    .dh-logo {
      background-image: url('~application/src/images/logo-mundus-agri-w400.png');
    }
  }

  .column-search {
    display: none;
    flex: 1 1 100%;
    order: 4;
    margin-top: 10px;
  }

  .column-icon-menu {
    order: 2;
    flex: 0 1 auto;
  }

  .column-flyout {
    order: 3;
    position: relative;
    overflow: hidden;
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-end;
  }

  .column-main-menu {
    order: 5;
    position: relative;
    flex: auto;
    margin: 0.25rem 0 0;
    padding: 0;
    top: 0;
    background-color: $white;
    z-index: 1;
    align-items: center;
    width: 100%;
    min-height: 0.5rem;
    text-align: right;
    white-space: nowrap;
    user-select: none;
  }

  .dh-main-nav {
    position: relative;
    z-index: 3;
    margin: 0;
    padding: 0;

    >ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .main-menu {
    margin-left: 0.65rem;

    i {
      width: 14px;
      overflow: hidden;
    }
  }
}

.dh-user-menu {
  display: flex;
  justify-content: space-between;
  flex: 1 1 100%;
  order: 3;
  line-height: 1;
  align-items: center;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 24px;
    height: 24px;
    font-size: 15px;
    line-height: 15px;
    border-radius: 200px;
    box-shadow: inset 0 0 0 1px $dark-gray;
    color: $body-font-color;
    user-select: none;
    transition: box-shadow 150ms ease-in-out, color 150ms ease-in-out;

    i {
      text-align: center;
    }

    &:hover {
      color: $mundus-agri-color;
      box-shadow: inset 0 0 0 1px $mundus-agri-color;
    }

    &.sidebar {
      span {
        display: none;
      }
    }
  }

  .dh-toggle {
    position: relative;
    margin-left: 0.25rem;

    &::after {
      right: 50%;
      top: 100%;
      position: absolute;
      z-index: 5;
      margin-top: 5px;
      transform: translateX(50%);
      @include css-triangle(5px, $light-gray, up);
      display: none;
    }

    &.is-open {
      color: $mundus-agri-color;
      box-shadow: inset 0 0 0 1px $mundus-agri-color;

      &::after {
        display: block;
      }
    }
  }

  .dh-toggle.language {
    display: flex;

    i {
      width: 1rem;
      box-shadow: none;
    }
  }

  .dh-toggle.search {
    display: flex;

    &>span {
      font-weight: bold;
      line-height: 12px;
      font-size: 12px;
    }

    &.is-open:after {
      border-color: transparent transparent $primary-color;
    }
  }

  .button-login {
    display: flex;
    align-items: center;
    margin-left: 0.65rem;

    i {
      font-size: 15px;
      line-height: 15px;
    }

    span {
      display: none;
    }
  }
}

.dh-flyout {
  position: relative;
  right: 0;
  top: 0;
  margin: 10px 0 0;
  max-width: 100%;
  width: 100%;
  min-width: 265px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background-color: $white;
  z-index: 3;

  @media (min-width:900px) and (min-height:600px) {
    max-width: 600px;
    width: auto;
  }

  .dh-flyout-head {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: $light-gray;
    display: flex;
    justify-content: flex-between;

    a {
      color: $dark-gray;
      cursor: pointer;
      text-decoration: none;
      user-select: none;
      margin-left: 1rem;
      transition: color 0.25s ease-out;

      &:hover {
        color: $mundus-agri-color;
      }
    }

    b {
      flex: 1
    }
  }

  .dh-flyout-content {
    &>div>a {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;

      &:not(:last-of-type) {
        border-bottom: 1px dotted $medium-gray;
      }
    }
  }

  .flag-icon {
    height: 1em;
    margin-right: 0.5rem;
    display: block;
  }

  .user-company {
    font-size: 0.85rem;
  }
}

@include breakpoint(600px) {
  #dedere-header {
    .dh-logo {
      width: 150px;
      height: 33px;
    }

    .dh-user-menu .icon {
      width: 32px;
      height: 32px;
      font-size: 18px;
      line-height: 18px;

      &.button-login {
        width: auto;
        height: auto;

        i {
          margin-right: 0.5rem;
        }

        span {
          display: inline;
          font-size: 1rem;
          line-height: initial;
        }
      }

      &.sidebar {
        width: auto;
        padding: 0 0.5rem;

        span {
          display: inline;
          margin-right: 0.25rem;
          line-height: 1;
          transform: translateY(1px);
          font-size: 14px;
        }
      }
    }

    .dh-toggle {
      margin-left: 0.65rem;
    }

    .dh-toggle.language {
      i {
        width: 1.2rem;
      }
    }

    .dh-toggle.search {
      display: none;
    }

    .column-search {
      display: block !important;
    }

    .dh-logo {
      width: 175px;
      height: 39px;
    }

    .dh-user-menu .button-login {
      width: auto;
      height: auto;
      font-size: 1em;
      line-height: initial;
      box-shadow: none;
      color: $mundus-agri-color;
      transition: color 0.25s ease-out;

      &:hover {
        color: $anchor-color-hover;
      }
    }
  }
}

@media (min-width:900px) and (min-height:600px) {
  #dedere-header {
    .dh-toggle {
      &::after {
        margin-top: 4px;
      }
    }

    .column-flyout {
      top: -4px;
      overflow: initial;
    }

    .dh-flyout {
      // width: auto;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 13px;
      box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    .dh-flyout.admin {
      width: 100%;
    }
  }
}

@include breakpoint($fold-main-menu-breakpoint-x) {
  #dedere-header {
    padding-top: 0.75rem;

    .dh-header-inner {
      align-items: center;
      margin-top: 0;
    }

    .dh-logo {
      width: 200px;
      height: 45px;

      a span {
        display: inline-block;
        position: absolute;
        padding: 3px 4px;
        background-color: #333;
        color: $white;
        right: 0;
        top: calc(100% + 2px);
        line-height: 1;
        font-size: 10px;

        &::after {
          content: '';
          position: absolute;
          border-width: 2px;
          border-style: solid;
          border-color: transparent;
          top: 0;
          right: 0;
          border-right-color: #333;
          border-bottom-color: #333;
          transform: translateY(-100%);
        }
      }
    }

    .column-search {
      flex: 1;
      order: 2;
      margin: 0 1.75rem;
    }

    .dh-flyout {
      margin-top: 4px;
    }
  }
}

@media (min-width:$fold-main-menu-breakpoint-x) and (min-height:$fold-main-menu-breakpoint-y) {
  #dedere-header {
    overflow: visible;

    .dh-logo {
      width: 200px;
      height: 45px;
    }

    .column-icon-menu {
      order: 3;
    }

    .column-flyout {
      order: 4;
      margin-top: 0.5rem;
    }

    .column-search {
      margin: 0 3.5rem;
    }

    .dh-flyout {
      margin-top: 0;
    }

    .dh-toggle {
      &::after {
        margin-top: 5px;
      }
    }
  }
}